<template>
  <main>
    <div class="list-wrap">
      <div class="overlay" v-if="tags.length === 0">
        <LoadingBlock/>
      </div>
      <div class="list" v-if="tags.length > 0">
        <div class="tag" v-for="tag in tags" :key="tag.name" @click="getContentForTag(tag.name)">
          <span class="name">{{ tag.name }}</span>
          <span class="counter">{{ tag.count }}</span>
        </div>
      </div>
    </div>
    <div class="tag-editor">
      <span class="heading" v-if="selectedTag">Тег #{{ selectedTag }}</span>
      <div class="management" v-if="selectedTag">
        <div
          class="button"
          @click="showOverlay('add')"
          :class="{ disabled: areButtonsBlocked }"
        >Добавить</div>
        <div
          class="button"
          @click="showOverlay('rename')"
          :class="{ disabled: areButtonsBlocked }"
        >Переименовать</div>
        <!--<div
          class="button"
          @click="showOverlay('merge')"
          :class="{ disabled: areButtonsBlocked }"
        >Объединить</div>-->
        <div
          class="button dangerous"
          @click="showOverlay('delete')"
          :class="{ disabled: areButtonsBlocked }"
        >Удалить</div>
      </div>
      <table v-if="content.length > 0">
        <thead>
          <tr>
            <th>Материал</th>
            <th>Симв.</th>
            <th>Просм.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in content" :key="`${c.type}/${c.slug}`">
            <td>
              <a :href="`/${$root.contentTypes[c.type].letter}/${c.slug}`" target="_blank">
                {{ c.title }}
              </a>
            </td>
            <td>{{ c.length }}</td>
            <td>{{ c.views }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="global-overlay" v-if="overlay">
      <div class="overlay-content" v-if="overlay === 'add'">
        <span>Ко всем материалам с тегом "#{{ selectedTag }}" будет добавлен указанный в поле тег.</span>
        <span class="strong">Это действие необратимо!</span>
        <input v-model="input" placeholder="Название добавляемого тега (без #)">
        <div class="actions">
          <div class="button" @click="addClicked" :class="{ disabled: areButtonsBlocked }">Добавить</div>
          <div class="button" @click="hideOverlay" :class="{ disabled: areButtonsBlocked }">Отменить</div>
        </div>
      </div>
      <div class="overlay-content" v-if="overlay === 'rename'">
        <span>Тег "#{{ selectedTag }}" будет переименован во всех материалах на указанный в поле.</span>
        <span>Если указанный в поле тег уже существует, переименование невозможно. В таком случае воспользуйтесь функцией объединения тегов.</span>
        <input v-model="input" placeholder="Новое название тега (без #)">
        <div class="actions">
          <div class="button" @click="renameClicked" :class="{ disabled: areButtonsBlocked }">Переименовать</div>
          <div class="button" @click="hideOverlay" :class="{ disabled: areButtonsBlocked }">Отменить</div>
        </div>
      </div>
      <!--<div class="overlay-content" v-if="overlay === 'merge'">
        <span>Тег "#{{ selectedTag }}" будет удалён, а во всех материалах с ним будет добавлен указанный в поле тег.</span>
        <span class="strong">Это действие необратимо!</span>
        <input v-model="input" placeholder="Название тега, с которым объединить">
        <div class="actions">
          <div class="button" :class="{ disabled: areButtonsBlocked }">Объединить</div>
          <div class="button" @click="hideOverlay" :class="{ disabled: areButtonsBlocked }">Отменить</div>
        </div>
      </div>-->
      <div class="overlay-content" v-if="overlay === 'delete'">
        <span>Тег "#{{ selectedTag }}" будет удалён из всех материалов.</span>
        <span>Чтобы подтвердить удаление, введите название тега (без решётки).</span>
        <span class="strong">Это действие необратимо!</span>
        <input v-model="input" placeholder="Название удаляемого тега (без #)">
        <div class="actions">
          <div class="button dangerous" @click="deleteClicked" :class="{ disabled: areButtonsBlocked }">Удалить</div>
          <div class="button" @click="hideOverlay" :class="{ disabled: areButtonsBlocked }">Отменить</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LoadingBlock from '@/components/Shared/LoadingBlock'
export default {
  name: 'TagManager',
  components: { LoadingBlock },

  data() {
    return {
      tags: [],
      selectedTag: null,
      content: [],
      overlay: null,
      input: '',
      areButtonsBlocked: false,
    }
  },

  mounted() {
    this.getTagList()
  },

  methods: {
    getTagList() {
      this.tags = []
      this.$root.makeAPIRequest('tag-manager', 'list', {})
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка: ${response.data.message}`)
            alert(response.data.message)
            return
          }

          this.tags = response.data.result.tags
        })
    },

    getContentForTag(tagName) {
      this.selectedTag = tagName
      this.content = []
      this.$root.makeAPIRequest('tag-manager', 'get', {
        slug: tagName,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка: ${response.data.message}`)
            alert(response.data.message)
            this.selectedTag = null
            return
          }

          this.content = response.data.result.content
        })
    },

    showOverlay(overlayName) {
      this.input = ''
      this.overlay = overlayName
    },

    hideOverlay() {
      this.input = ''
      this.overlay = null
    },

    addClicked() {
      if(this.areButtonsBlocked) {
        return
      }

      const trimmedInput = this.input.trim()
      if(trimmedInput.length < 1) {
        return
      }

      this.areButtonsBlocked = true

      this.$root.makeAPIRequest('tag-manager', 'add', {
        to: this.selectedTag,
        slug: trimmedInput,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка: ${response.data.message}`)
            alert(response.data.message)
            this.areButtonsBlocked = false
            return
          }

          this.areButtonsBlocked = false
          this.hideOverlay()
          this.getTagList()
        })
        .catch(() => {
          alert('Произошла ошибка. Проверьте подключение к интернет.')
          this.areButtonsBlocked = false
        })
    },

    renameClicked() {
      if(this.areButtonsBlocked) {
        return
      }

      const trimmedInput = this.input.trim()
      if(trimmedInput.length < 1) {
        return
      }

      this.areButtonsBlocked = true

      this.$root.makeAPIRequest('tag-manager', 'rename', {
        slug: this.selectedTag,
        newslug: trimmedInput,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка: ${response.data.message}`)
            alert(response.data.message)
            this.areButtonsBlocked = false
            return
          }

          this.areButtonsBlocked = false
          this.hideOverlay()
          this.getTagList()
          this.selectedTag = trimmedInput
        })
        .catch(() => {
          alert('Произошла ошибка. Проверьте подключение к интернет.')
          this.areButtonsBlocked = false
        })
    },

    deleteClicked() {
      if(this.areButtonsBlocked) {
        return
      }

      const trimmedInput = this.input.trim()
      if(trimmedInput.length < 1) {
        return
      }

      if(trimmedInput !== this.selectedTag) {
        alert('Введённый тег не совпадает с выбранным удаляемым. Проверьте данные, в том числе регистр букв. Если проблема продолжается, попробуйте сначала переименовать тег')
        return
      }

      this.areButtonsBlocked = true

      this.$root.makeAPIRequest('tag-manager', 'delete', {
        slug: this.selectedTag,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка: ${response.data.message}`)
            alert(response.data.message)
            this.areButtonsBlocked = false
            return
          }

          this.areButtonsBlocked = false
          this.hideOverlay()
          this.getTagList()
          this.selectedTag = null
          this.content = []
        })
        .catch(() => {
          alert('Произошла ошибка. Проверьте подключение к интернет.')
          this.areButtonsBlocked = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';

  @list-wrap-width: 250px;

  main {
    width: var(--content-width);
    align-self: center;
    min-height: 200px;

    display: flex;
  }

  .list-wrap {
    position: relative;
    flex-basis: @list-wrap-width;
    max-width: @list-wrap-width;
    flex-shrink: 0;
    overflow: hidden;
    word-wrap: break-word;
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .tag {
    margin-top: 0.5em;
    align-self: flex-start;
    cursor: pointer;
    max-width: 100%;

    &:first-child {
      margin-top: unset;
    }

    .counter {
      font-size: 0.75em;
      opacity: 50%;
      margin-left: 0.5em;
    }
  }

  .tag-editor {
    position: relative;
    flex-shrink: 0;

    width: calc(var(--content-width) - @list-wrap-width - @s-margin);
    margin-left: @s-margin;
  }

  .heading {
    font-weight: 700;
    display: block;
    font-size: 20pt;
    overflow: hidden;
    word-wrap: break-word;
    margin-bottom: 1em;
  }

  .management {
    margin-bottom: 1em;

    display: flex;
    flex-wrap: wrap;
  }

  .button {
    background-color: @color-accent;
    color: @color-block-background;
    padding: 0.5em 1em;

    &+& {
      margin-left: 0.5em;
    }

    &.dangerous {
      background-color: @color-error;
    }

    &.disabled {
      opacity: 0.3;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;

    td,
    th {
      border: 1px solid @color-border;
      border-collapse: collapse;
      padding: 0.2em 0.35em;
    }

    a {
      font-weight: 700;
    }
  }

  .overlay {
    .overlay();
  }

  .global-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: hsla(0, 0%, 0%, 0.5);
    z-index: @z-index-global-overlay;

    display: flex;
  }

  .overlay-content {
    margin: auto;
    background-color: @color-block-background;
    padding: @s-margin;
    max-width: 320px;

    display: flex;
    flex-direction: column;

    input {
      .input();
      margin-top: 0.5em;
    }

    &>*+* {
      margin-top: 0.5em;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .strong {
    font-weight: 700;
  }
</style>
